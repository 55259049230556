<template>
    <div class="Projectcation" style="margin-top:20px;">
        <div class="Project_button">
            <span>用户类型</span>
            <el-select v-model="value" size="small" @change="yearChange(value)" style="margin-right:10px">
                <el-option label="院系管理员" value="1"></el-option>
                <el-option label="评审专家" value="9"></el-option>
            </el-select>
            <el-button type="primary" size="mini" @click=" addstuid()">添加用户</el-button>
        </div>
        <el-table 
            :data="tableData" 
            style="width: 100%;height:97%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="name" label="院系名称"></el-table-column>
            <el-table-column prop="phoneNumber" label="账号"></el-table-column>
            <el-table-column label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button @click="deleted(scope.row)" size="mini" type="danger">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="User_Popup">
            <el-dialog
                title="添加用户"
                :visible.sync="dialogVisible"
                width="500px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
                    <el-form-item label="用户类型" prop="type">
                        <el-select v-model="ruleForm.type" placeholder="请选择用户类型">
                            <el-option label="院系管理员" value="1"></el-option>
                            <el-option label="评审专家" value="9"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="this.ruleForm.type==1?'院系名称':'专家姓名'" prop="name"
                    :rules="[
                        { required: true, message: this.ruleForm.type==1?'请选择院系':'请输入专家姓名', trigger: 'blur' },
                    ]">
                        <el-select v-model="ruleForm.academyId" placeholder="请选择院系" v-show="this.ruleForm.type==1" @change="Selectdepart(ruleForm.academyId)">
                            <el-option
                                v-for="(item,index) in departList"
                                :key="index"
                                :label="item.yuanName" 
                                :value="item.yuanId"></el-option>
                        </el-select>
                        <el-input v-model="ruleForm.name" placeholder="请输入专家姓名" v-show="this.ruleForm.type!=1"></el-input>
                    </el-form-item>
                    <el-form-item label="账号" prop="phoneNumber">
                        <el-input v-model="ruleForm.phoneNumber" placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="ruleForm.password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="addmine('ruleForm')">确 定</el-button>
                    <el-button size="small" @click="closei('ruleForm')">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            dialogVisible:false,
            ruleForm:{
                type:'',
                name:'',
                phoneNumber:'',
            },
            rules:{
                type:[
                    { required: true, message: '请选择用户类型', trigger: 'change' }
                ],
                phoneNumber:[
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    {
                        pattern: /^\d{11}$/,
                        message: "账号为11位数字"
                    }
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
            },
            tableData:[],
            value:'1',
            departList:[],
        }
    },
    methods: {
        getapp(){
            this.axios.adminlist({
                params:{
                    uid:this.$store.state.uid,
                    type:this.value
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
        },
        //选择用户类型
        Selectdepart(val){
            this.departList.map(item=>{
                if(item.yuanId==val){
                    this.ruleForm.name = item.yuanName
                }
            })
        },
        yearChange(){
            this.getapp()
        },
        addstuid(){
            this.axios.studentuid({
                params:{
                    uid:this.$store.state.uid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.dialogVisible = true
                    this.departList = res.data.data.yuanxi
                }else{
                    this.departList = []
                }
            }).catch(err=>{

            })
        },
        //添加确定
        addmine(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.schoolId = this.$store.state.schId
                    this.axios.adminbuild(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisible =false
                            this.value = this.ruleForm.type
                            this.getapp()
                            this.$refs[formName].resetFields();
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //删除
        deleted(rows){
            this.$confirm('此操作永久删除该记录，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.admindelete({
                    params:{
                        id:rows.adId
                    }
                }).then(res=>{
                    if(res.data.code==200){
                        this.getapp()
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        handleClose(done){
            console.og(done)
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.User_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.User_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.User_Popup .el-dialog__footer{
    text-align: center;
}
.User_Popup .el-select{
    width: 100%;
}
.User_Popup .el-form-item__content{
    width: 270px;
}
.User_Popup .el-dialog__body{
    padding: 30px 50px;
}
.Projectcation .el-table__body-wrapper{
    min-height: calc(100vh - 334px);
}
.Projectcation .el-table__empty-block{
    min-height: calc(100vh - 334px);
}
</style>